import { FC } from 'react';

import { parseLink } from '@lib/slice-machine/linkResolver';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, Grid } from '@mui/material';
import { ProtectedNavItem } from '@templates/common/layout/page-layout/footer/ProtectedNavItem';

import { ListItem } from '../common/ListItem';
import { DynamicImage } from '@ui/gallery/DynamicImage';
import { NodeLink } from '@ui/buttons/NodeLink';

export const LinkList: FC = () => {
  const { footerMenu, tenant } = useTenantProps();
  const { slices } = footerMenu ?? { slices: [] };
  const columnsPerRow = parseInt(footerMenu?.columnsPerRow as string, 10) ?? 2;
  const activeColor = tenant.theme?.specifics?.footer?.activeColor;
  const dynamicStyles = styles();

  if (!slices || !slices?.length) {
    return null;
  }

  return (
    <Grid item md={8} xs={12} container alignItems="flex-start" spacing={2}>
      {slices.map((slice, i) => {
        const isNavigationLinkItem = slice.variation === 'navigationItem';
        const isImageLinkItem = slice.variation === 'default';

        return (
          <Grid
            key={i}
            item
            xs={6}
            md={12 / columnsPerRow}
            container
            spacing={1}
            direction="column"
            alignItems={{ xs: 'center', md: 'flex-start' }}
          >
            <Grid item>
              <ListItem
                sx={dynamicStyles.getValue('title')}
                activeColor={activeColor}
                {...parseLink(slice.primary.textLink)}
              />
            </Grid>
            <Grid item textAlign={{ xs: 'center', md: 'left' }}>
              {isNavigationLinkItem ? (
                slice.items.map((item, j) => (
                  <ProtectedNavItem navItem={item} key={j}>
                    <ListItem
                      activeColor={activeColor}
                      {...parseLink(item.textLink)}
                    />
                  </ProtectedNavItem>
                ))
              ) : isImageLinkItem ? (
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                  {slice.items.map((item, j) => (
                    <NodeLink
                      href={item.link?.url || '#'}
                      target="_blank"
                      key={j}
                    >
                      <Box sx={dynamicStyles.getValue('imageWrapper')}>
                        {item?.image?.url && (
                          <DynamicImage
                            fill
                            objectFit="fill"
                            src={item.image?.url}
                            alt={item.image?.alt}
                          />
                        )}
                      </Box>
                    </NodeLink>
                  ))}
                </Box>
              ) : null}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

const styles = () =>
  new SxStyles({
    imageWrapper: {
      width: 40,
      height: 35,
    },
    title: {
      fontWeight: 'fontWeightMedium',
      fontSize: 14,
      color: 'text.primary',
    },
  });
