import React, { FC } from 'react';

import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { SHARED_PALETTE } from '@lib/theme/constants';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, Container, Grid, Typography } from '@mui/material';
import { DynamicImage } from '@ui/gallery/DynamicImage';

import { BottomBar } from './BottomBar';
import { LinkList } from './LinkList';

export const Footer: FC = () => {
  const { footerMenu } = useTenantProps();

  if (!footerMenu) {
    return null;
  }

  return (
    <Box sx={styles.getValue('root')}>
      <Container maxWidth="xl" sx={styles.getValue('container')}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={4}
            container
            direction="column"
            alignItems="center"
          >
            {footerMenu?.logoTitle && (
              <Typography variant="body2" sx={styles.getValue('title')}>
                {footerMenu?.logoTitle}
              </Typography>
            )}
            {footerMenu?.logo?.url && (
              <Box sx={styles.getValue('imageWrapper')}>
                <DynamicImage
                  containerProps={{ sx: { maxWidth: 200 } }}
                  src={footerMenu?.logo.url}
                  alt={footerMenu.logo.alt}
                  width={footerMenu.logo.dimensions.width ?? 200}
                  height={150}
                  objectFit="contain"
                />
              </Box>
            )}
          </Grid>
          <LinkList />
        </Grid>
        <BottomBar footnote={footerMenu?.footnote} />
      </Container>
    </Box>
  );
};

const styles = new SxStyles({
  root: {
    display: 'flex',
    py: 6,
    bgcolor: SHARED_PALETTE.lightGrey,
  },
  container: {
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  title: {
    fontWeight: 'fontWeightMedium',
    fontSize: 14,
    color: 'text.primary',
    textAlign: 'center',
  },
  imageWrapper: {
    pb: 0.5,
  },
});
